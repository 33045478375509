
import { Component, Vue } from 'vue-property-decorator';
import { IPrompt, IPromptUpdate } from '@/interfaces';
import {
  dispatchGetPrompts,
  dispatchUpdatePrompt,
  dispatchRemovePrompt,
} from '@/store/main/actions';
import { readOnePrompt } from '@/store/main/getters';

@Component
export default class EditPrompt extends Vue {
  public valid = true;
  public name: string = '';
  public description: string = '';
  public template: string = '';
  public type: string = '';
  public variables: string = '';
  public tags: string = '';

  public async mounted() {
    await dispatchGetPrompts(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.prompt) {
      this.name = this.prompt.name;
      this.description = this.prompt.description;
      this.template = this.prompt.template;
      this.type == this.prompt.type;
      this.variables = this.prompt.variables;
      this.tags = this.prompt.tags;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async remove() {
    console.debug("EditPrompt.remove... " + this.prompt!.id);
    await dispatchRemovePrompt(this.$store, { id: this.prompt!.id });
    this.$router.push('/main/prompts');
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedPrompt: IPromptUpdate = {};
      if (this.name) {
        updatedPrompt.name = this.name;
      }
      if (this.description) {
        updatedPrompt.description = this.description;
      }
      if (this.template) {
        updatedPrompt.template = this.template;
      }
      if (this.variables) {
        updatedPrompt.variables = this.variables;
      }
      if (this.tags) {
        updatedPrompt.tags = this.tags;
      }
      if (this.type) {
        updatedPrompt.type = this.type;
      }
      await dispatchUpdatePrompt(this.$store, { id: this.prompt!.id, prompt: updatedPrompt });
      this.$router.push('/main/prompts');
    }
  }

  get prompt() {
    return readOnePrompt(this.$store)(+this.$router.currentRoute.params.id);
  }
}
